<template>
  <div>
    <kovi-page-title :text="$t('maintenance.titles.scheduledDeliveryView')" />

    <b-row>
      <b-col sm="12">
        <scheduled-delivery-list />
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import KoviPageTitle from '@components/shared/kovi-page-title';
  import ScheduledDeliveryList from '@components/schedule/scheduled-delivery-list/ScheduledDeliveryList';

  export default {
    name: 'schedule-delivery',

    components: {
      KoviPageTitle,
      ScheduledDeliveryList,
    },
  };

</script>

<template>
  <b-row>
    <b-col cols="12">
      <h2>{{ text }}</h2>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'KoviPageTitle',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
